export const darkTheme = {
  colors: {
    bgPrimary: "#0B0F15",
    bgSecondary: "#1D232B",
    bgChat: "rgba(28, 35, 43, 0.5)",
    bgTertiary: "#303740",
    brandDefault: "#F5C950",
    textPrimary: "#F5C950",
    textSecondary: "rgba(255,255,255, 0.72)",
    textDisabled: "rgba(255,255,255, 0.38)",
    textInvert: "rgba(0,0,0,0.9)",
    iconBtn: "$white",
    iconHoverBg: "rgba(255, 255, 255, 0.25)",
    transparentBg: "rgba(0, 0, 0, 0.22)",
    mainBg: "$black",
    tileBg: "#212121",
    previewBg: "#111111",
    statsBg: "rgba(0,0,0,0.75)",
    menuBg: "$grayDark",
    inputBg: "$bgSecondary",
    toastBg: "$menuBg",
  },
};

// TODO: Update light theme colors from design
export const lightTheme = {
  colors: {
    bgPrimary: "#FFFFFF",
    bgSecondary: "#F5F5F5",
    bgTertiary: "rgba(255,255,255, 0.38)",
    textPrimary: "rgba(0,0,0, 0.9)",
    textSecondary: "rgba(0,0,0, 0.72)",
    textDisabled: "rgba(0,0,0, 0.38)",
    textInvert: "rgba(255,255,255, 0.9)",
    iconBtn: "#F5C950",
    iconHoverBg: "rgba(0, 0, 0, 0.22)",
    transparentBg: "rgba(255, 255, 255, 0.22)",
    mainBg: "$white",
    tileBg: "#F2F2F2",
    previewBg: "#FAFAFA",
    statsBg: "rgba(255,255,255,0.75)",
    menuBg: "#d9d9d9",
    inputBg: "$tileBg",
    toastBg: "$menuBg",
  },
};
