import { HMSMessage } from "@100mslive/react-sdk";
import { Chat } from "./Chat/Chat";

export const ChatView = ({
  roomId,
  storedMessages,
}: {
  roomId: string;
  storedMessages: HMSMessage[];
}) => {
  return <Chat roomId={roomId} storedMessages={storedMessages} />;
};
