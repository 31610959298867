import { useContext } from "react";
import {
  Flex,
  Text,
  textEllipsis,
  Box,
  styled,
} from "@100mslive/roomkit-react";
import { SpeakerIcon } from "@100mslive/react-icons";
import { useHMSStore, selectDominantSpeaker } from "@100mslive/react-sdk";
import { Invite } from "./Invite";
import { ParticipantList } from "./ParticipantList";
import { AppContext } from "../context/AppContext";

const SpeakerTag = () => {
  const dominantSpeaker = useHMSStore(selectDominantSpeaker);
  return dominantSpeaker && dominantSpeaker.name ? (
    <Flex
      align="center"
      justify="center"
      css={{ flex: "1 1 0", color: "$textPrimary", "@md": { display: "none" } }}
    >
      <SpeakerIcon width={24} height={24} />
      <Text
        variant="md"
        css={{ ...textEllipsis(200), ml: "$2" }}
        title={dominantSpeaker.name}
      >
        {dominantSpeaker.name}
      </Text>
    </Flex>
  ) : (
    <></>
  );
};

const LogoImg = styled("img", {
  maxHeight: "$28",
  p: "$2",
  w: "auto",
  "@md": {
    maxHeight: "$24",
  },
});

const Logo = () => {
  const { logo } = useContext(AppContext);
  return (
    <LogoImg
      src={logo}
      alt="Brand Logo"
      width={264}
      height={60}
      style={{ height: "60px" }}
    />
  );
};

export const Header = ({ isPreview }: any) => {
  // const localPeerRole = useHMSStore(selectLocalPeerRoleName);
  const isPreviewRoom = !window.location.href.includes("/live");
  const welcomeLanguage = isPreviewRoom ? " presents" : "";
  return (
    <Flex
      justify="between"
      align="center"
      css={{ position: "relative", height: "100%" }}
    >
      <Flex align="center" css={{ position: "absolute", left: "$4" }}>
        <Logo />

        <Text
          css={{
            color: "#f5c950",
            marginBottom: "6px",
            fontSize: "24px",
            textAlign: "center",
          }}
        >
          {welcomeLanguage}
        </Text>
      </Flex>
      <SpeakerTag />
      {!isPreview && (
        <Flex align="center" css={{ position: "absolute", right: "$4" }}>
          <Box css={{ mx: "$2" }}>
            <Invite />
          </Box>
          <Box css={{ mx: "$2" }}>
            <ParticipantList />
          </Box>
        </Flex>
      )}
    </Flex>
  );
};
