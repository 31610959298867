import axios from "axios";
import { getTimezone } from "countries-and-timezones";
import Animated_Gif from "gif-transparency";
import { Dispatch, SetStateAction } from "react";

import hrclogo from "../images/hrc_header.base64";
import pcflogo from "../images/pcf_curing_together_transparent.base64";

// Tuning parameters for GIF creation
const delayMs = 100;
const gifHeight = 480;
const gifWidth = 480;
const totalImages = 10;

export function shadeColor(color: any, percent: any) {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = Math.floor((R * (100 + percent)) / 100);
  G = Math.floor((G * (100 + percent)) / 100);
  B = Math.floor((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR =
    R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
  const GG =
    G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
  const BB =
    B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
}

/**
 * TODO: this is currently an O(N**2) function, don't use with peer lists, it's currently
 * being used to find intersection between list of role names where the complexity shouldn't matter much.
 */
export const arrayIntersection = (a?: any[], b?: any[]) => {
  if (a === undefined || b === undefined) {
    return [];
  }
  // ensure "a" is the bigger array
  if (b.length > a.length) {
    let t = b;
    b = a;
    a = t;
  }
  return a.filter(function (e: any) {
    return b?.indexOf(e)! > -1;
  });
};

export const getMetadata = (metadataString: any) => {
  try {
    return metadataString === "" ? {} : JSON.parse(metadataString);
  } catch (error) {
    return {};
  }
};

export const metadataProps = function (peer: any) {
  return {
    isHearted: getMetadata(peer.metadata)?.isHearted,
  };
};

export const mobileChatStyle = {
  position: "absolute",
  bottom: "0",
  zIndex: 40,
  width: "100%",
  maxWidth: "95vw",
  height: "47vh",
  maxHeight: "47vh",
};

export const isScreenshareSupported = () => {
  return typeof navigator.mediaDevices.getDisplayMedia !== "undefined";
};

export const capture = (stream: any) => {
  const video = document.createElement("video");
  video.playsInline = true; // required for ios in order to prevent fullscreen
  video.muted = true; // required to prevent audio feedback from temporary video

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  video.srcObject = stream;

  return new Promise((resolve, reject) => {
    video.addEventListener("loadeddata", async () => {
      const { videoWidth, videoHeight } = video;
      // Sample a square in the center of video of up to 1080x1080 of center
      // const sampleWidth = Math.max(960, videoWidth / 4.0, videoHeight / 4.0);
      // const sampleHeight = sampleWidth;

      const resultWidth = videoWidth;
      const resultHeight = resultWidth;

      canvas.width = resultWidth;
      canvas.height = resultHeight;

      // center square of 320x320
      try {
        await video.play();
        await video.play();
        if (context) {
          context.drawImage(video, 0, 0);

          // Image watermark
          try {
            const res_r = await fetch(pcflogo);
            const blob_r = await res_r.blob();
            const image = await createImageBitmap(blob_r, 20, 40, 460, 200, {
              resizeHeight: 40,
              resizeWidth: 97,
              resizeQuality: "high",
            });
            const pattern_r = context.createPattern(image, "repeat")!;
            context.fillStyle = pattern_r;
            context.fillRect(
              canvas.width - image.width,
              canvas.height - image.height,
              canvas.width,
              canvas.height
            );

            const res_l = await fetch(hrclogo);
            const blob_l = await res_l.blob();
            const image_l = await createImageBitmap(blob_l, 0, 0, 176, 116, {
              resizeHeight: 48,
              resizeWidth: 73,
              resizeQuality: "high",
            });
            const pattern_l = context.createPattern(image_l, "repeat")!;

            context.fillStyle = pattern_l;
            context.fillRect(
              0,
              canvas.height - image_l.height,
              image_l.width,
              image_l.height
            );
          } catch (e) {
            console.error({ e });
          }
          canvas.toBlob(resolve, "image/png");
        }
      } catch (error) {
        reject(error);
      }
    });
  });
};

/**
 * getBase64FromBlob method to convert a blob to base64 for web transport
 * @param file Binary file in blob format
 * @returns Promise to resolve a string into a file: Blob
 */
export const getBase64FromBlob = async (file: any) => {
  return new Promise((resolve: (value: string) => void, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader.result !== null && typeof reader.result === "string") {
        resolve(reader.result);
      } else {
        console.log("Reader result is not string.");
      }
    };
    reader.onerror = error => reject(error);
  });
};

interface chatMsg {
  fingerprint: string;
  content: string;
  avatar: string;
  sender_id: string;
  sender_name: string;
  popup_uuid: string;
  metadata: string;
  thread_uuid: string;
}
export const postChats = async (msg: chatMsg) => {
  const now = new Date();
  let hotjar_id = "unknown";
  if (window.hj !== undefined && window.hj?.globals) {
    hotjar_id = window.hj?.globals?.get("userId");
  }
  // POST all content to API
  try {
    axios.post("https://api.subwifi.com/chats", {
      key: msg.fingerprint,
      content: msg.content,
      utc_ms: now.getTime() * 1000 + now.getUTCMilliseconds(),
      avatar: msg.avatar,
      sender_id: msg.sender_id,
      sender_name: msg.sender_name,
      popup_uuid: msg.popup_uuid,
      metadata: `{'hotjar_user_id': '${hotjar_id}', 'msg_type': '${msg.metadata}', 'visitor_id': '${msg.fingerprint}'`,
      thread_uuid: msg.thread_uuid,
    });
  } catch (e) {
    console.log(`Could not post chat: ${JSON.stringify(msg)}  because ${e}`);
  }
};

export const registerFingerprint = (fp: any) => {
  let visitor_timezone = "unknown";
  let timezone_offset = null;
  try {
    visitor_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    timezone_offset = getTimezone(visitor_timezone)?.utcOffset;
  } catch (e) {
    console.log(
      "Your browser doesn't support Intl and/or retrieving utc offset."
    );
  }
  try {
    axios.post("https://api.subwifi.com/fingerprints", {
      key: fp,
      visitor_id: fp,
      visitor_timezone: visitor_timezone,
      timezone_offset: timezone_offset,
    });
  } catch (error) {
    console.error(`error posting to /fingerprints: ${error}`);
  }
};

/**
 *
 * @param param0 useState methods related to snap button UI
 * @param localStream video stream
 * @returns
 */
export const photoShare = async (
  [snapButtonPressed, setSnapButtonPressed]: [
    boolean,
    Dispatch<SetStateAction<boolean>>
  ],
  localStream: any
) =>
  new Promise((res, _rej) => {
    // Allow a multiple of the sampling window to generate blob+base64. TODO: tune this value per device or use another syncronization method
    const processingTimeMultiple = 2;
    const totalTimeBudgetMs = delayMs * totalImages * processingTimeMultiple;
    if (snapButtonPressed) return; // do not fire off until process has returned
    const images: HTMLImageElement[] = new Array<HTMLImageElement>(totalImages); // array of images captured at delayMs intervals
    hotjar_event("share");
    if (localStream) {
      let hotjar_id = "unknown";
      if (window.hj !== undefined && window.hj?.globals) {
        hotjar_id = window.hj?.globals?.get("userId");
      }
      for (let i = 0; i < totalImages; i++) {
        setTimeout(() => {
          capture(localStream).then(blob => {
            getBase64FromBlob(blob).then((base64: string) => {
              const imgElem = new Image();
              imgElem.src = base64;
              images[i] = imgElem; // place in specific location in the sequence
            });
          });
        }, i * delayMs * processingTimeMultiple);
      }
      // wait for images to be settled then return whatever has been populated.
      setTimeout(() => {
        setSnapButtonPressed(false); // clear the useState
        console.log({ images });
        return res(images);
      }, totalTimeBudgetMs * processingTimeMultiple);
    } else {
      res([]);
    }
  });
/**
 *
 * @param images
 * @param visitor_id
 * @returns
 */
export const create_gif = (images: any, visitor_id: any) =>
  new Promise((res: (value: string) => any, _rej) => {
    const gif = new Animated_Gif();
    gif.setSize(gifHeight, gifWidth);
    gif.setDelay(delayMs * 2);
    images.forEach((img: any) => {
      gif.addFrame(img);
    });
    gif.getBase64GIF((image: any) => {
      res(image);
    });
  });

export const hotjar_event = (type: any) => {
  if (!type) return;
  const event = type + "_" + new Date().toISOString().slice(11);
  window.hj("event", event);
};

export const getPopupChatHistory = async (key: string, roomId: string) => {
  if (!key || !roomId) return [];
  try {
    const response = await axios.get(
      "https://api.subwifi.com/chats?key=" + key + "&popup_uuid=" + roomId
    );
    const result = await response.data;
    return result;
  } catch (e) {
    console.log(
      `Error retrieving chat history from room ${roomId} because ${e}`
    );
  }
};
