import React, { Fragment, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import {
  HMSMessage,
  selectHMSMessages,
  selectLocalPeerID,
  selectMessagesByPeerID,
  selectMessagesByRole,
  selectPeerNameByID,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";
import { Flex, styled } from "@100mslive/roomkit-react";
import { Text } from "../../react-ui/src";
import { v4 } from "uuid";

const formatTime = (time: Date) => {
  let hours = time.getHours();
  const minutes = time.getMinutes();
  const notation = hours / 12 >= 1 ? " PM" : " AM";
  hours = hours % 12;
  if (hours == 0) hours = 12;
  return hours + ":" + (minutes < 10 ? "0" + minutes : minutes) + notation;
};

const MessageType = ({ roles, hasCurrentUserSent, receiver }: any) => {
  const peerName = useHMSStore(selectPeerNameByID(receiver));
  if (receiver) {
    return (
      <Text css={{ variant: "sm", mx: "$4" }}>
        {hasCurrentUserSent ? `to ${peerName}` : "to me"}
        <Text as="span" css={{ variant: "sm", color: "$error", mx: "$4" }}>
          (Privately)
        </Text>
      </Text>
    );
  }

  if (roles && roles.length) {
    return (
      <Text css={{ variant: "sm", mx: "$4" }}>
        to
        <Text as="span" css={{ variant: "sm", color: "$error", mx: "$4" }}>
          ({roles.join(",")})
        </Text>
      </Text>
    );
  }
  return (
    <Text css={{ variant: "sm", mx: "$4" }}>
      to
      <Text as="span" css={{ variant: "sm", color: "$grayLight", mx: "$4" }}>
        Everyone
      </Text>
    </Text>
  );
};

const URL_REGEX =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

const Link = styled("a", {
  color: "$brandDefault",
  wordBreak: "break-all",
  "&:hover": {
    textDecoration: "underline",
  },
});

const AnnotisedChat = ({ message }: any) => {
  if (!message) {
    return <Fragment />;
  }

  return (
    <Fragment>
      {message
        .trim()
        .split(" ")
        .map((part: any) =>
          URL_REGEX.test(part) ? (
            <Link
              href={part}
              key={part}
              target="_blank"
              rel="noopener noreferrer"
            >
              {part}{" "}
            </Link>
          ) : (
            `${part} `
          )
        )}
    </Fragment>
  );
};

interface ChatMessageProps {
  message: HMSMessage;
}

const ChatMessage = React.memo(({ message }: ChatMessageProps) => {
  const { ref, inView } = useInView({ threshold: 0.5, triggerOnce: true });
  const hmsActions = useHMSActions();
  const localPeerId = useHMSStore(selectLocalPeerID);

  useEffect(() => {
    if (message.id && !message.read && inView) {
      hmsActions.setMessageRead(true, message.id);
    }
  }, [message.read, hmsActions, inView, message.id]);

  return (
    <Flex
      ref={ref}
      css={{ flexWrap: "wrap", p: "0 $4" }}
      key={message.time.toString()}
    >
      <Text css={{ variant: "sm", color: "$grayLight" }}>
        {message.senderName
          ? message.message.includes(" into the room!")
            ? "SubWire"
            : message.sender === localPeerId
            ? "You"
            : message.senderName
          : "Anonymous"}
      </Text>
      {false && (
        <MessageType
          hasCurrentUserSent={message.sender === localPeerId}
          receiver={message.recipientPeer}
          roles={message.recipientRoles}
        />
      )}
      <Text css={{ variant: "sm", ml: "auto", color: "White" }}>
        {formatTime(message.time)}
      </Text>
      {message.message.startsWith("data:image") ? (
        <Text css={{ w: "100%", my: "$2", wordBreak: "break-word" }}>
          <img src={message.message} alt="message" height="auto" width="100%" />
        </Text>
      ) : (
        <Text
          css={{
            w: "100%",
            my: "$2",
            wordBreak: "break-word",
            fontWeight: "$medium",
            fontFamily: "$sans",
          }}
        >
          <AnnotisedChat message={message.message} />
        </Text>
      )}
    </Flex>
  );
});

export const ChatBody = ({ role, peerId, messages }: any) => {
  const storeMessageSelector = role
    ? selectMessagesByRole(role)
    : peerId
    ? selectMessagesByPeerID(peerId)
    : selectHMSMessages;

  messages = messages.concat(useHMSStore(storeMessageSelector) || []);
  if (messages?.length === 0) {
    return (
      <Flex
        css={{
          width: "100%",
          height: "50%",
          textAlign: "center",
          px: "$2",
        }}
        align="center"
        justify="center"
      >
        <Text css={{ color: "White" }}>Live stories and selfies go here.</Text>
      </Flex>
    );
  }
  return (
    <Fragment>
      {messages?.map((message: HMSMessage) => {
        return <ChatMessage key={message.id || v4()} message={message} />;
      })}
    </Fragment>
  );
};
